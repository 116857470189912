import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DocumentWithFile } from '@app/models/form-data.interface';
import { DOCUMENT_TYPE } from '@app/models/registration-form';
import { acceptedFileTypes } from '../documents-upload.component';
import { DocumentDetails } from '@app/constants/document-mappings';

@Component({
  selector: 'app-document-upload-section',
  templateUrl: './document-upload-section.component.html',
  styleUrls: ['./document-upload-section.component.scss'],
})
export class DocumentUploadSectionComponent {
  @Input() public allTouched = false;
  @Input() public multipleDocumentUpload = true;
  @Input() public documentName?: string;
  @Input() public documentDescription?: string;
  @Input() public documentDetails!: DocumentDetails;
  @Input() public documentControl!: FormControl;
  @Input() public documentControlName!: string;

  public acceptedFileTypes = acceptedFileTypes;
  public readonly maxAllowedSizeMB = 10;
  public documentTouched = false;
  public readonly maxSizeUnit = 'MB';
  public readonly acceptedFileTypesString = acceptedFileTypes
    .map(type => type.label)
    .join(', ');

  public selectFile(fileUpload: HTMLInputElement): void {
    fileUpload.click();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onFileSelected(event: any): void {
    this.documentTouched = true;
    const file = event.target.files[0] as File;
    const maxAllowedSize = 1024 * 1024 * this.maxAllowedSizeMB;

    if (file.size > maxAllowedSize) {
      this.documentControl.setErrors({
        maxSize: { size: this.maxAllowedSizeMB, unit: this.maxSizeUnit },
      });
      return;
    }
    if (
      !this.acceptedFileTypes.map(fileType => fileType.type).includes(file.type)
    ) {
      this.documentControl.setErrors({
        fileType: {
          acceptedTypes: this.acceptedFileTypesString,
        },
      });
      return;
    }
    if (this.multipleDocumentUpload) {
      this.addSelectedDocuments(file, this.documentDetails.typeName);
    } else {
      this.documentControl.setValue({
        file,
        type: this.documentDetails.typeName,
        name: file.name,
      });
    }
  }

  private addSelectedDocuments(file: File, documentType: DOCUMENT_TYPE): void {
    this.getSelectedDocuments().push({
      file,
      type: documentType,
      name: file.name,
    });
    this.documentControl.patchValue(this.getSelectedDocuments());
  }

  private getSelectedDocuments(): DocumentWithFile[] {
    return this.documentControl.value as DocumentWithFile[];
  }

  public removeSelectedDocument(document?: DocumentWithFile): void {
    if (this.multipleDocumentUpload) {
      if (document) {
        const index = this.getSelectedDocuments().indexOf(document, 0);

        if (index > -1) {
          this.getSelectedDocuments().splice(index, 1);
          this.documentControl.patchValue(this.getSelectedDocuments());
        }
      }
    } else {
      this.documentControl.setValue(null);
    }
  }
}
