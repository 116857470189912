import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: true,
  environment: '$ENVIRONMENT',
  apiUrl: `$API_URL`,
  hostOverride: undefined,
  consentBannerEnabled: `$CONSENT_BANNER_ENABLED`,
  dynamicHostOverrideEnabled: `$DYNAMIC_HOST_OVERRIDE_ENABLED`,
  mainDomain: `$MAIN_DOMAIN`,
  analyticsTagId: `$ANALYTICS_TAG_ID`,
  turnstileSiteKey: '$TURNSTILE_SITE_KEY',
};
