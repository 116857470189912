import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AnalyticsService } from '@app/services/analytics.service';
import { AppStateService } from '@app/services/app-state.service';
import { EnvironmentService } from '@app/services/environment.service';
import { RouteService } from '@app/services/route.service';
import {
  PublicCustomerConfigurationDto,
  WhiteLabelService,
} from '@app/services/white-label.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, take } from 'rxjs';
import {
  APP_LANGUAGE,
  DEFAULT_APP_LANGUAGE,
  LanguageKey,
} from '@app/constants/app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly usercentricsAnalyticsEvent = 'Contentsquare';
  private appLanguage!: LanguageKey;
  public whiteLabelConfiguration$: Observable<
    PublicCustomerConfigurationDto | undefined
  >;

  constructor(
    private whiteLabelService: WhiteLabelService,
    private environment: EnvironmentService,
    private translateService: TranslateService,
    private routeService: RouteService,
    private appStateService: AppStateService,
    private _renderer2: Renderer2,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.whiteLabelConfiguration$ = whiteLabelService.whiteLabelConfig$;
    this.routeService.init(this.whiteLabelService.hasWhitelabel());
  }

  public ngOnInit(): void {
    this.setApplicationLanguageAndDateLocale();
    this.analyzeWhiteLabelConfiguration();
    this.initConsentBanner();
  }

  private setApplicationLanguageAndDateLocale(): void {
    const browserLanguage =
      this.translateService.getBrowserLang() as LanguageKey;
    const availableLanguages = Object.values(APP_LANGUAGE).map(
      languageLocale => languageLocale.locale
    );

    this.appLanguage = availableLanguages.includes(browserLanguage)
      ? browserLanguage
      : DEFAULT_APP_LANGUAGE.locale;

    this.translateService.use(this.appLanguage);
  }

  @HostListener('window:beforeunload', ['$event'])
  public checkUnsavedChanges(event: BeforeUnloadEvent): void {
    if (this.appStateService.isModified()) {
      // custom messages in dialogs are not supported
      // it is just used to show the default dialog if needed
      // see: https://developer.chrome.com/blog/chrome-51-deprecations/#remove-custom-messages-in-onbeforeunload-dialogs
      // (behaviour is similar for every modern browser)
      event.returnValue = 'Wait!';
    }
  }

  private analyzeWhiteLabelConfiguration(): void {
    this.whiteLabelConfiguration$
      .pipe(filter(Boolean), take(1))
      .subscribe(config => this.appStateService.initialiseModule(config));
  }

  private initConsentBanner(): void {
    if (this.environment.isConsentBannerEnabled) {
      const script = this._renderer2.createElement('script');

      script.id = `usercentrics-cmp`;
      script.type = `application/javascript`;
      script.src = `https://app.usercentrics.eu/browser-ui/latest/loader.js`;
      script.setAttribute('data-settings-id', 'Kpwq7_5C1vKFS1');
      script.setAttribute('data-language', this.appLanguage);
      script.async = true;
      this._renderer2.appendChild(this._document.head, script);
      this.watchConsentChange();
    }
  }

  private watchConsentChange(): void {
    addEventListener('ucEvent', ucEvent =>
      this.modifyAnalyticsTracking(ucEvent)
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private modifyAnalyticsTracking(ucEvent: any): void {
    if (ucEvent.detail?.event === 'consent_status') {
      if (ucEvent.detail[this.usercentricsAnalyticsEvent] === true) {
        this.analyticsService.startTracking();
      } else {
        this.analyticsService.stopTracking();
      }
    }
  }

  public ngOnDestroy(): void {
    removeEventListener('ucEvent', this.modifyAnalyticsTracking);
  }
}
