import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en-GB';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@app/modules/shared/shared.module';
import { IconInitializerService } from '@app/services/icon-initializer.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { initializeApp } from './app-initialization/app-initializer-factory';
import { WebpackTranslateLoader } from './app-initialization/webpack-translation-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { CommonStepsModule } from './modules/common-steps/common-steps.module';
import { ElectricityModule } from './modules/electricity/electricity.module';
import { GasModule } from './modules/gas/gas.module';
import { WaterModule } from './modules/water/water.module';
import { WhiteLabelService } from './services/white-label.service';
import { HeatModule } from './modules/heat/heat.module';
import { CommissioningElectricityModule } from './modules/commissioning-electricity/commissioning-electricity.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from '@app/services/custom-date-adapter.service';
import { httpErrorInterceptor } from '@app/interceptors/http-error.interceptor';

registerLocaleData(localeDE);
registerLocaleData(localeEN);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CommonStepsModule,
    ElectricityModule,
    CommissioningElectricityModule,
    GasModule,
    WaterModule,
    HeatModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      defaultLanguage: 'de',
    }),
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        IconInitializerService,
        WhiteLabelService,
        HttpClient,
        Sentry.TraceService,
      ],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    provideHttpClient(withInterceptors([httpErrorInterceptor])),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
