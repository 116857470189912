<app-form-card
  titleIcon="drive_folder_upload"
  cardTitle="DOCUMENTS_UPLOAD.HEADING"
  dataTestId="documents-upload-heading">
  <form [formGroup]="documentForm">
    <p [innerHTML]="'DOCUMENTS_UPLOAD.DESCRIPTION' | translate"></p>
    <div class="space"></div>

    <app-document-upload-section
      *ngFor="let document of availableDocumentTypes"
      [allTouched]="allTouched"
      [documentDetails]="document"
      [documentControlName]="document.typeName"
      [documentControl]="getControl(document.typeName)"
      class="document-upload-section"
      [attr.data-testid]="document.typeName">
    </app-document-upload-section>

    <section *ngIf="showCommentSection">
      <div class="space"></div>
      <h2>
        {{ 'DOCUMENTS_UPLOAD.ANYTHING_ELSE' | translate }}
      </h2>
      <div class="input-container">
        <mat-form-field appearance="outline" class="textarea-field">
          <mat-label>{{ 'DOCUMENTS_UPLOAD.NOTES' | translate }}</mat-label>
          <textarea
            #notes
            matInput
            cols="50"
            rows="6"
            [maxlength]="maxLength"
            formControlName="notes"
            data-testid="notes"></textarea>
          <mat-hint align="end"
            >{{ notes.value.length }} / {{ maxLength }}</mat-hint
          >
          <mat-error appErrorMessages="notes"></mat-error>
        </mat-form-field>
      </div>
    </section>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: !allRequiredFormsProvided,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
